import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AuditOutlined,
  HomeOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";

import Navbar from "./navbar";
import Footer from "./footer";

import { AppContext } from "../context/appContext";
import languageResources from "../enums/language";

const { Content, Sider } = Layout;

const LayoutWrapper = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { menuSelected, setMenuSelected, languageSelected } =
    useContext(AppContext);

  const navigate = useNavigate();

  const alternItems = [
    {
      key: "home",
      icon: <HomeOutlined />,
      label: languageResources[languageSelected].menu.home,
    },
    {
      key: "orders",
      icon: <SolutionOutlined />,
      label: languageResources[languageSelected].menu.orders,
      children: [
        {
          key: "orders",
          label: languageResources[languageSelected].menu.myOrders,
        },
        {
          key: "createOrder",
          label: languageResources[languageSelected].menu.newOrder,
        },
      ],
    },
    {
      key: "invoices",
      icon: <AuditOutlined />,
      label: languageResources[languageSelected].menu.invoices,
    },
  ];

  const onSelect = (values) => {
    setMenuSelected(values.key);
    navigate("/" + values.keyPath[0]);
  };

  return (
    <div>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{ backgroundColor: "#2C3E50" }}
        >
          {!collapsed ? (
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <img
                src="https://deliparva.com/wp-content/uploads/2024/07/Deliparva-Logo-color.webp"
                alt=""
                style={{ width: "80%" }}
              />
            </div>
          ) : (
            <></>
          )}

          <Menu
            theme="dark"
            onSelect={onSelect}
            defaultOpenKeys={["sub0"]}
            selectedKeys={[menuSelected]}
            mode="inline"
            items={alternItems}
            style={{ backgroundColor: "#2C3E50" }}
          />
        </Sider>
        <Layout>
          <Navbar />

          <Content
            style={{
              margin: "16px",
            }}
          >
            {children}
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </div>
  );
};

export default LayoutWrapper;
