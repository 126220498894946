import React from "react";
import ViewSalesOrder from "../components/viewSalesOrder";
import { useLocation, useParams } from "react-router-dom";

const OrderDetail = () => {
  const { id } = useParams(); 
  const location = useLocation(); 
  const orderDetail = location.state?.orderDetail; 
  console.log({orderDetail, location})

  return <ViewSalesOrder orderDetail={orderDetail} title={'Order Detail ' + id} />;
};

export default OrderDetail;
