import React from 'react';
import { Input, Button, Space } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const QuantityInput = ({value, setValue}) => {


  const increase = () => {
    setValue(value + 1);
  };

  const decrease = () => {
    if (value > 1) {
      setValue(value - 1);
    }
  };

  return (
    <Space>
      <Button
        onClick={decrease}
        icon={<MinusOutlined />}
        type="primary"
        shape="circle"
        size='large'
      />
      <Input
        min={1}
        value={value}
        onChange={setValue}
        size='large'
        style={{ width: 60, textAlign: 'center' }}
        stile={{textAlign: 'center'}}
        readOnly
      />
      <Button
        onClick={increase}
        icon={<PlusOutlined />}
        type="primary"
        shape="circle"
        size='large'
      />
    </Space>
  );
};

export default QuantityInput;
