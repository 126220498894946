import React from "react";
import { Layout } from "antd";

const { Footer: FooterAnt } = Layout;

const Footer = () => {
  return (
    <FooterAnt
      style={{
        textAlign: "center",
      }}
    >
      Powered by DELIPARVA 2024 All rights
    </FooterAnt>
  );
};

export default Footer;
