import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [orderDetail, setOrderDetail] = useState();
  const [menuSelected, setMenuSelected] = useState("home");
  const [languageSelected, setLanguageSelected] = useState("EN");

  const logout = () => {
    setUserInfo(null);
    setIsAuthenticated(false);
  };

  const availableValues = {
    isAuthenticated,
    setIsAuthenticated,
    logout,
    menuSelected,
    setMenuSelected,
    languageSelected,
    setLanguageSelected,
    userInfo,
    setUserInfo,
    orderDetail,
    setOrderDetail,
  };

  return (
    <AppContext.Provider value={availableValues}>
      {children}
    </AppContext.Provider>
  );
};
