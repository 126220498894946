import { Card, Spin, Row, Col, Typography } from "antd";
import React, { useContext, useMemo } from "react";
import { AppContext } from "../../context/appContext";
import useRunReport from "../../hooks/useRunReport";

const { Title, Text } = Typography;

const SummarySlfOrder = () => {
  const { userInfo } = useContext(AppContext);
  const reportName = "Summary SelfOrder";
  const filters = useMemo(
    () => ({ customer: userInfo.cliente_id }),
    [userInfo]
  );
  const { data: summary, isLoading } = useRunReport(reportName, filters);

  // Datos de resumen (pueden ser undefined si no hay datos)
  const summaryData = summary?.result || [];

  // Función para obtener el conteo y el total de un estado específico
  const getStatusData = (status) => {
    const statusData = summaryData.find((item) => item.status === status);
    return {
      order_count: statusData?.order_count || 0,
      total_amount_sum: statusData?.total_amount_sum || 0,
    };
  };

  // Datos para cada estado
  const createdData = getStatusData("Created");
  const processingData = getStatusData("Processing");
  const completedData = getStatusData("Completed");

  return (
    <Spin spinning={isLoading}>
      {/* Card principal con el título "Orders Summary" */}
      <Card title="Orders Summary">
        <Row gutter={[16, 16]}>
          {/* Card para "Created" */}
          <Col xs={24} sm={8} lg={8}>
            <Card>
              <Title level={2} style={{ textAlign: "center" }}>
                {createdData.order_count}
              </Title>
              <Text strong style={{ display: "block", textAlign: "center" }}>
                Created
              </Text>
              <Text style={{ display: "block", textAlign: "center" }}>
                Total: ${createdData.total_amount_sum.toFixed(2)}
              </Text>
            </Card>
          </Col>

          {/* Card para "Processing" */}
          <Col xs={24} sm={8} lg={8}>
            <Card>
              <Title level={2} style={{ textAlign: "center" }}>
                {processingData.order_count}
              </Title>
              <Text strong style={{ display: "block", textAlign: "center" }}>
                Processing
              </Text>
              <Text style={{ display: "block", textAlign: "center" }}>
                Total: ${processingData.total_amount_sum.toFixed(2)}
              </Text>
            </Card>
          </Col>

          {/* Card para "Completed" */}
          <Col xs={24} sm={8} lg={8}>
            <Card>
              <Title level={2} style={{ textAlign: "center" }}>
                {completedData.order_count}
              </Title>
              <Text strong style={{ display: "block", textAlign: "center" }}>
                Completed
              </Text>
              <Text style={{ display: "block", textAlign: "center" }}>
                Total: ${completedData.total_amount_sum.toFixed(2)}
              </Text>
            </Card>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default SummarySlfOrder;