import axios from "axios";

export const BACK_API = () => {
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_PASSWORD;

  const headers = {
    "Content-type": "application/x-www-form-urlencoded",
    "Accept": "*/*",
    "Authorization": `token ${username}:${password}`,
  };
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`, 
    headers
  });

  return instance;
};

export default BACK_API;
