import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Form, Input, Button, message, Typography } from "antd";
import { AppContext } from "../context/appContext";
import loginToERPNext from "../helpers/loginToERPNext";


const { Title } = Typography;

const Login = () => {
  const { setUserInfo, setIsAuthenticated } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    const { username, password } = values;
    setLoading(true);
    const res = await loginToERPNext(username, password);
    setLoading(false);

    console.log({ ...res.result });
    if (res.status === 200) {
      setUserInfo({
        ...res.result[0],
      });
      setIsAuthenticated(true);
      navigate("/home");
    } else {
      message.error("Usuario o contraseña inválida");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "#f5f5f5",
        }}
      >
        <Card
          style={{
            width: 400,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Title level={2} style={{ textAlign: "center" }}>
            Welcome to
          </Title>
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <img
              src="https://deliparva.com/wp-content/uploads/2024/07/Deliparva-Logo-color.webp"
              alt=""
              style={{ width: "80%" }}
            />
          </div>
          <Form
            layout="vertical"
            onFinish={handleLogin}
            initialValues={{ username: "", password: "" }}
          >
            <Form.Item
              label="User"
              name="username"
              rules={[
                { required: true, message: "Please, enter your email" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please, enter your password" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Login;
