import BACK_API from "../api/erpNextAPI";
import { message } from "antd";

const createDocType = async (docTypeInfo) => {
  try {
    const result = await BACK_API().post(
      `proxy/api/resource/SelfServiceOrder`,
      docTypeInfo, // Convertir a JSON
      {
        headers: {
          "Content-Type": "application/json", // Especificar el tipo de contenido
        },
      }
    );

    // Validar respuesta del servidor
    if (result?.data?.data) {
      // message.success("El documento fue creado exitosamente.");
      return result.data.data; // Retorna el objeto del DocType creado
    } else {
      throw new Error("La respuesta del servidor no es válida.");
    }
  } catch (error) {
    // Manejo de errores
    console.error("Error al crear el DocType:", error.message || error);
    message.error(`No fue posible guardar el documento: ${error.message}`);
    return false; // Indicar que hubo un fallo
  }
};

export default createDocType;
