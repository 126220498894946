import BACK_API from "../api/erpNextAPI";
import { message } from "antd";

const runReport = async (reportName, filters) => {

  const data = {
    report_name: reportName,
    filters: JSON.stringify(filters),
  };

  try {
    const result = await BACK_API().post(
      `proxy/api/method/frappe.desk.query_report.run`,
      data
    );

    return result?.data?.message || [];
  } catch (error) {
    console.log(error);
    message.error("No fue posible ejecutar el reporte " + reportName);
    return [];
  }
};



export default runReport;
