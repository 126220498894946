import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Input,
  DatePicker,
  Table,
  Button,
  Spin,
  message,
  Select,
  Typography,
  Tooltip,
  Steps,
  Modal,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/appContext";
import dayjs from "dayjs";
import useRunReport from "../hooks/useRunReport";
import QuantityInput from "./quantityInput";
import createDocType from "../helpers/postCreateDocType";
import ModalUpdateItem from "./modalUpdateItem";

const { Title } = Typography;
const { Step } = Steps;

const formatToCurrency = (number) => `$${(+number || 0).toFixed(2)}`;

const ViewSalesOrder = ({ orderDetail, title }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { userInfo, setMenuSelected } = useContext(AppContext);
  const isNew = useMemo(() => !orderDetail, [orderDetail]);
  const reportName = "Detail Price List";
  const filters = useMemo(
    () => ({ priceList: userInfo.price_list_por_defecto }),
    [userInfo]
  );

  const [dataSource, setDataSource] = useState([]);
  const [qtyToAdd, setQtyToAdd] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [frmLoading, setFrmLoading] = useState(false);
  const [totals, setTotals] = useState({ total_qty: 0, base_total: 0 });
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { data: itemList, isLoading } = useRunReport(reportName, filters);
  const [currentState, setCurrentState] = useState()
  const [columns, setColumns] = useState([
    {
      title: "Code",
      dataIndex: "item_code",
      align: "center",
      key: "item_code",
    },
    {
      title: "Description",
      dataIndex: "item_name",
      align: "center",
      key: "item_name",
    },
    {
      title: "Group",
      dataIndex: "item_group",
      align: "center",
      key: "item_group",
    },
    { title: "Qty", dataIndex: "qty", align: "center", key: "qty" },
    {
      title: "Price",
      dataIndex: "rate",
      align: "center",
      key: "rate",
      render: (_, { rate }) => formatToCurrency(rate),
    },
    {
      title: "Subtotal",
      dataIndex: "amount",
      align: "center",
      key: "amount",
    },
  ]);

  const options = !isLoading
    ? itemList.result.map((agr) => ({
        value: agr.item_code,
        label: `${agr.item_code}: ${agr.item_name}`,
      }))
    : [];

  useEffect(() => {
    if (orderDetail) {
      form.setFieldsValue({
        salesOrder: orderDetail.name,
        customer: orderDetail.customer_name,
        date: dayjs(orderDetail.transaction_date),
        address: formatStringForTextarea(orderDetail.address_display),
        deliveryDate: dayjs(orderDetail.delivery_date),
        totalTaxesAndCharges: formatToCurrency(
          orderDetail.total_taxes_and_charges
        ),
        grandTotal: formatToCurrency(orderDetail.total_amount),
        totalQty: orderDetail.total_qty,
        totalItems: formatToCurrency(orderDetail.total_amount),
      });
      setTotals({
        total_qty: orderDetail.total_qty,
        base_total: orderDetail.total_amount,
      });
      setDataSource(orderDetail.items);
      if(orderDetail.status === 'Created') {
        setCurrentState(1)
      }
      if(orderDetail.status === 'Processing') {
        setCurrentState(2)
      }
      if(orderDetail.status === 'Completed') {
        setCurrentState(3)
      }
    } else {
      const dynamicColumns = [
        ...columns,
        {
          title: "Options",
          dataIndex: "",
          align: "center",
          key: "x",
          render: (record) => (
            <>
              <Tooltip title="Edit">
                <Button
                  size="large"
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => showEditItem(record)}
                />
              </Tooltip>
              {/* <Tooltip title="Delete">
                <Button
                  size="large"
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    eraseRecord(record);
                  }}
                />
              </Tooltip> */}
            </>
          ),
        },
      ];
      setColumns(dynamicColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail]);

  const showEditItem = (record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };
  const formatStringForTextarea = (inputString = "") =>
    inputString.replace(/<br\s*\/?>/gi, "\n");

  const eraseRecord = async (record) => {
    const newArray = dataSource.filter(
      (obj) => obj.item_code !== record.item_code
    );
    await setDataSource(newArray);
    console.log("Registro eliminado:", record);
    console.log("Nuevo dataSource:", newArray);

    setTotals((prev) => ({
      total_qty: prev.total_qty - record.qty,
      base_total: parseFloat(
        (prev.base_total - record.qty * record.rate).toFixed(2)
      ),
    }));
  };

  const onSelect = (selectedString) => {
    const selectedCode = selectedString.split(" - ")[0].trim();
    const matchedItem = itemList?.result?.find(
      (item) => item.item_code === selectedCode
    );
    setSelectedItem(matchedItem || null);
  };

  const addItem = async () => {
    const {
      price_list_item_name: key,
      item_code,
      item_name,
      item_group,
      price_list_rate,
      custom_units_per_box,
    } = selectedItem;

    const itemExists = dataSource.some((obj) => obj.item_code === item_code);
    if (itemExists) {
      message.error(`Item ${item_name} is already on the list`);
      return;
    }

    const newItem = {
      key,
      item_code,
      item_name,
      item_group,
      qty: qtyToAdd,
      rate: price_list_rate,
      custom_units_per_box,
      amount: parseFloat((qtyToAdd * price_list_rate).toFixed(2)),
    };

    setDataSource((prev) => [...prev, newItem]);
    setTotals((prev) => ({
      total_qty: prev.total_qty + qtyToAdd,
      base_total: parseFloat(
        (prev.base_total + qtyToAdd * price_list_rate).toFixed(2)
      ),
    }));

    setSelectedItem(null);
    setQtyToAdd(1);
  };

  const disabledDate = (current) => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return current && current.toDate() <= today;
  };

  const onFinish = async (values) => {
    const { date, deliveryDate } = values;
    const errors = [];

    if (dataSource.length === 0)
      errors.push("Debe incluir al menos un producto.");
    if (!date) errors.push("Debe incluir la fecha de creación.");
    if (!deliveryDate) errors.push("Debe incluir la fecha de envío.");

    if (errors.length > 0) {
      message.error(
        <div>
          {errors.map((err, index) => (
            <p key={index}>{err}</p>
          ))}
        </div>,
        5
      );
      return;
    }

    const finalValues = dataSource.reduce(
      (acc, item) => ({
        total_qty: acc.total_qty + item.qty,
        total_amount:
          acc.total_amount + item.qty * parseFloat(item.rate.toFixed(2)),
      }),
      { total_qty: 0, total_amount: 0 }
    );

    setFrmLoading(true);
    const bodyRequest = {
      customer: userInfo.cliente_id,
      customer_name: userInfo.nombre_cliente,
      order_date: date.format("YYYY-MM-DD"),
      delivery_date: deliveryDate.format("YYYY-MM-DD"),
      status: "Created",
      total_amount: finalValues.total_amount,
      total_qty: finalValues.total_qty,
      price_list: userInfo.price_list_por_defecto,
      items: dataSource.map((item) => ({
        item_code: item.item_code,
        item_name: item.item_name,
        custom_units_per_box: item.custom_units_per_box,
        qty: item.qty,
        rate: parseFloat(item.rate).toFixed(2),
        amount: item.qty * parseFloat(item.rate).toFixed(2),
        delivery_date: deliveryDate.format("YYYY-MM-DD"),
      })),
    };

    const response = await createDocType(bodyRequest);
    setFrmLoading(false);
    console.log(response)

    if (response.status) {
      Modal.success({
        title: "Order Created",
        content:
          `Thank you for your order. We have created it with the reference ${response.name}. We’ll confirm it once we check our availability.`,
        onOk: () => {
          setMenuSelected("orders");
          navigate("/orders/");
        },
      });
    }
  };

  const disabledDeliveryDate = (current) => {
    const dateInitial = form.getFieldValue("date");
    const today = new Date(dateInitial);
    today.setHours(23, 59, 59, 999);
    return current && current.toDate() <= today;
  };

  const updateQty = (record, newQty) => {
    const updatedDataSource = dataSource.map((item) => {
      if (item.item_code === record.item_code) {
        return {
          ...item,
          qty: newQty,
          amount: parseFloat((newQty * item.rate).toFixed(2)),
        };
      }
      return item; 
    });

    setDataSource(updatedDataSource);

    const newTotals = updatedDataSource.reduce(
      (acc, item) => ({
        total_qty: acc.total_qty + item.qty,
        base_total: parseFloat((acc.base_total + item.amount).toFixed(2)),
      }),
      { total_qty: 0, base_total: 0 }
    );

    setTotals(newTotals);
  };

  return (
    <Spin spinning={isLoading || frmLoading}>
      <Row justify="space-between">
        <Col>
          {!isNew && (
            <Tooltip title="Back">
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
              />
            </Tooltip>
          )}
        </Col>

        <Col>
          <Title level={2} style={{ textAlign: "center" }}>
            {title}
          </Title>
        </Col>
        <Col></Col>
      </Row>

      <Form
        name="formSalesOrder"
        onFinish={onFinish}
        onFinishFailed={() =>
          message.error("Diligencie la información requerida")
        }
        layout="vertical"
        size="large"
        form={form}
        disabled={!isNew}
      >
        {!isNew && (
          <Card style={{ marginBottom: "10px" }}>
            <Row justify="center">
              <Col xs={24} sm={24} lg={24}>
                <Steps current={currentState}>
                  <Step title="Created" />
                  <Step title="Processing" />
                  <Step title="Completed" />
                </Steps>
              </Col>
            </Row>
          </Card>
        )}

        <Card style={{ marginBottom: "10px" }}>
          <p>
            <strong>General Info</strong>
          </p>
          <Row>
            {!isNew && (
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label="Sales Order" name="salesOrder">
                  <Input readOnly style={{ width: "90%" }} />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Customer" name="customer">
                <Input
                  readOnly
                  style={{ width: "90%" }}
                  defaultValue={userInfo.nombre_cliente}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Date" name="date">
                <DatePicker
                  disabledDate={disabledDate}
                  style={{ width: "90%" }}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese la fecha para cumplimiento",
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Delivery Date" name="deliveryDate">
                <DatePicker
                  style={{ width: "90%" }}
                  disabledDate={disabledDeliveryDate}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese la fecha para cumplimiento",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card style={{ marginBottom: "10px" }}>
          <p>
            <strong>Items</strong>
          </p>
          {isNew && (
            <Card style={{ marginBottom: "20px" }}>
              <Row justify="space-between">
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    label="Search Item"
                    name="searchItem"
                    style={{ width: "90%" }}
                  >
                    <Select
                      showSearch
                      onSelect={onSelect}
                      placeholder="Search an Item"
                      allowClear
                      value={selectedItem}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      options={options}
                      onClear={() => setSelectedItem()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <Form.Item label=" " style={{ width: "90%" }}>
                    <QuantityInput value={qtyToAdd} setValue={setQtyToAdd} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <Form.Item label=" " style={{ width: "90%" }}>
                    <Button disabled={!selectedItem} onClick={addItem}>
                      Add Item
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-around">
                <Col xs={24} sm={12} lg={3}>
                  <Form.Item label="Code">
                    <Input value={selectedItem?.item_code} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Description">
                    <Input value={selectedItem?.item_name} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <Form.Item label="Group">
                    <Input value={selectedItem?.item_group} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={3}>
                  <Form.Item label="Units per Box">
                    <Input value={selectedItem?.custom_units_per_box} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={3}>
                  <Form.Item label="Price">
                    <Input value={selectedItem?.price_list_rate} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          )}

          <Row justify="center">
            <Col xs={24} sm={24} lg={24}>
              <Table
                style={{ width: "100%", marginBottom: "20px" }}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              />
            </Col>
          </Row>
          <Row justify="space-between">
            <Col xs={24} sm={24} lg={12} style={{ textAlign: "left" }}>
              <Form.Item
                label="Total Qty"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input style={{ width: "70%" }} value={totals.total_qty} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} lg={12} style={{ textAlign: "right" }}>
              <Form.Item
                label={
                  <span style={{ display: "block", textAlign: "right" }}>
                    Total
                  </span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  style={{ width: "70%", textAlign: "right" }}
                  value={totals.base_total}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        {isNew && (
          <Row align="center">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                size="large"
              >
                Save Order
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
      <ModalUpdateItem
        record={selectedRecord}
        showModal={showModal}
        updateQty={updateQty}
        onCancel={() => setShowModal(false)}
      />
    </Spin>
  );
};

export default ViewSalesOrder;
