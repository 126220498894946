import { Modal, Collapse, Table } from "antd";
import React from "react";

const ModalDetailDoc = ({ data, visible, onClose }) => {


  const items = [
    {
      key: "1",
      label: "General Info",
      children: <p>General Info</p>,
    },
    {
      key: "2",
      label: "Items",
      children: <Table dataSource={[]} columns={[]} />,
    },
    {
      key: "3",
      label: "Payment Info",
      children: <p>Payment Info</p>,
    },
  ];

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      title={`Detail Sales Invoice ${data?.name}`}
    >
      <Collapse items={items} />
    </Modal>
  );
};

export default ModalDetailDoc;
