import { useEffect, useState } from "react";
import getDocTypeList from "../helpers/getDocTypeList";

const useGetDocTypeList = (docType, fields, filters, orderBy) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getDocTypeList(docType, fields, filters, orderBy).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [docType, filters, fields, orderBy]);

  return { data, isLoading };
};

export default useGetDocTypeList;
