import React from "react";
import SummarySlfOrder from "../components/dashboards/summarySlfOrder";


const Home = () => {
  return (
    <>
      <SummarySlfOrder />
    </>
  );
};

export default Home;
