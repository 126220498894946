import BACK_API from "../api/erpNextAPI";
import { message } from "antd";

// Función para obtener detalles de cada ítem de una factura
const getSalesInvoiceItem = async (itemName, doctype) => {
  try {
    const result = await BACK_API().get(`/proxy/api/resource/${doctype}/${itemName}`);
    return result.data.data;
  } catch (error) {
    console.log(error);
    message.error(`No fue posible consultar el ítem: ${itemName}`);
    return null;
  }
};

export default getSalesInvoiceItem