import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login";
import Home from "./pages/home";
import PrivateRoute from "./components/privateRoute";
import LayoutWrapper from "./components/layout";
import Orders from "./pages/orders";
import Invoices from "./pages/invoices";
import CreateOrder from "./pages/createOrder";
import OrderDetail from "./pages/orderDetail";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/login" element={<Login />} />

        {/* Rutas protegidas con Layout */}
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <LayoutWrapper>
                <Home />
              </LayoutWrapper>
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <LayoutWrapper>
                <Orders />
              </LayoutWrapper>
            </PrivateRoute>
          }
        />
        <Route
          path="/createOrder"
          element={
            <PrivateRoute>
              <LayoutWrapper>
                <CreateOrder />
              </LayoutWrapper>
            </PrivateRoute>
          }
        />
        <Route
          path="/invoices"
          element={
            <PrivateRoute>
              <LayoutWrapper>
                <Invoices />
              </LayoutWrapper>
            </PrivateRoute>
          }
        />

        <Route
          path="/createOrder"
          element={
            <PrivateRoute>
              <LayoutWrapper>
                <CreateOrder />
              </LayoutWrapper>
            </PrivateRoute>
          }
        />

        <Route
          path="/detailOrder/:id"
          element={
            <PrivateRoute>
              <LayoutWrapper>
                <OrderDetail />
              </LayoutWrapper>
            </PrivateRoute>
          }
        />

        {/* Ruta comodín para redirigir al Home */}
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </Router>
  );
};

export default App;
