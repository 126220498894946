import BACK_API from "../api/erpNextAPI";
import { message } from "antd";
import qs from "qs";

const getDocTypeList = async (doctypeName, fields, filters, orderBy) => {
  if(filters === undefined || filters[0] === undefined) {
    filters = undefined
  }
  const limit_start = 0;
  const limit_page_length = 2500;
  const queryParams =
    filters !== undefined
      ? qs.stringify({
          filters: JSON.stringify(filters),
          fields: JSON.stringify(fields),
          order_by: orderBy,
          limit_start: JSON.stringify(limit_start),
          limit_page_length: JSON.stringify(limit_page_length),
        })
      : qs.stringify({
          fields: JSON.stringify(fields),
          limit_start: JSON.stringify(limit_start),
          limit_page_length: JSON.stringify(limit_page_length),
        });

  const data =
    filters !== undefined
      ? {
          fields,
          filters,
          limit_start: 0,
          limit_page_length: 2500,
        }
      : {
          fields,
          limit_start: 0,
          limit_page_length: 2500,
        };

  const options = {
    method: "GET",
    url: `proxy/api/resource/${doctypeName}?${queryParams}`,
    data,
  };

  const result = await BACK_API()
    .request(options)
    .catch((error) => {
      console.log(error);
      message.error("No fue posible consultar los " + doctypeName);
      return null;
    });

  if (result && result.data) {
    return result.data.data;
  }

  return [];
};

export default getDocTypeList;
