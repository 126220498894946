import React, { useContext, useState } from "react";
import { FilePdfOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import useGetDocTypeList from "../hooks/useGetDocTypeList";
import { AppContext } from "../context/appContext";
import ViewerDoctype from "../components/viewerDoctype";
import getPrintFormatPDF from "../helpers/getPrintFormatPDF";
import getSalesInvoiceItem from "../helpers/getSalesInvoiceItem";
import ModalDetailDoc from "../components/modalDetailDoc";
import languageResources from "../enums/language";

const Invoices = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const { userInfo, languageSelected } = useContext(AppContext);
  const [docType] = useState("Sales Invoice");
  const [orderBy] = useState("posting_date DESC");
  const [fields] = useState([
    "name",
    "posting_date",
    "customer",
    "customer_name",
    "due_date",
    "grand_total",
  ]);
  const [filter] = useState([
    ["customer", "=", userInfo.cliente_id],
    ["status", "!=", "cancelled"],
  ]);
  const { data, isLoading } = useGetDocTypeList(
    docType,
    fields,
    filter,
    orderBy
  );

  const columns = [
    {
      title: "Date",
      dataIndex: "posting_date",
      align: "center",
      key: "posting_date",
    },
    {
      title: "Sales Order",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Payment Due Date",
      dataIndex: "due_date",
      align: "center",
      key: "due_date",
    },
    {
      title: "Total",
      dataIndex: "grand_total",
      align: "center",
      key: "grand_total",
    },
    {
      title: languageResources[languageSelected].page.salesOrders.actions,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (record) => {
        return (
          <>
            <Tooltip title="Descargar Orden">
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<FilePdfOutlined />}
                onClick={() => downloadDocument(record)}
              />
            </Tooltip>
            <Tooltip title="Ver detalle">
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<SearchOutlined />}
                onClick={() => searchDetail(record)}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const downloadDocument = (record) => {
    const resPDF = getPrintFormatPDF(
      "Sales Order",
      record.name,
      "SO Custom JAS 01"
    );
    console.log(resPDF);
  };

  const searchDetail = async (record) => {
    const resDetail = await getSalesInvoiceItem(record.name, "Sales Invoice");
    console.log(resDetail);
    setModalData(resDetail);
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <ViewerDoctype
        columns={columns}
        data={data}
        isLoading={isLoading}
        title={languageResources[languageSelected].page.salesInvoice.title}
      />
      <ModalDetailDoc visible={showModal} onClose={onClose} data={modalData} />
    </>
  );
};

export default Invoices;
