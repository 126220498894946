import { Modal, Button, Typography, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import QuantityInput from "./quantityInput";

const { Text } = Typography;

const ModalUpdateItem = ({ record, showModal, updateQty, onCancel }) => {
  const [value, setValue] = useState(1);

  // Inicializar el valor de la cantidad con el valor actual del registro
  useEffect(() => {
    if (record) {
      setValue(record.qty);
    }
  }, [record]);

  // Función para manejar la actualización de la cantidad
  const handleUpdate = () => {
    if (record && value !== record.qty) {
      updateQty(record, value); // Llamar a la función updateQty para actualizar el registro
    }
    onCancel(); // Cerrar el modal
  };

  return (
    <Modal
      open={showModal}
      title="Update Item Quantity"
      onOk={handleUpdate}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="update" type="primary" onClick={handleUpdate}>
          Update
        </Button>,
      ]}
    >
      {record && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Text strong>Item Code:</Text>
            </Col>
            <Col span={12}>
              <Text>{record.item_code}</Text>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Text strong>Description:</Text>
            </Col>
            <Col span={12}>
              <Text>{record.item_name}</Text>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Text strong>New Quantity</Text>
            </Col>
            <Col span={12}>
              <QuantityInput value={value} setValue={setValue} />
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

export default ModalUpdateItem;
