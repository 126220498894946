import React, { useContext } from "react";
import {
  Menu,
  Dropdown,
  Avatar,
  Row,
  Col,
  Layout,
  Space,
  Typography,
} from "antd";
import { UserOutlined, LogoutOutlined, DownOutlined, GlobalOutlined } from "@ant-design/icons";
import { AppContext } from "../context/appContext";

const { Header } = Layout;
const { Text } = Typography;

const Navbar = () => {
  const { logout, userInfo, languageSelected, setLanguageSelected } =
    useContext(AppContext);

  const handleLogout = () => {
    logout();
  };

  // Opciones del selector de idioma
  const languageMenu = (
    <Menu
      onClick={({ key }) => setLanguageSelected(key)}
      selectedKeys={[languageSelected]}
    >
      <Menu.Item key="EN">English (EN)</Menu.Item>
            <Menu.Item key="ES">Español (ES)</Menu.Item>
    </Menu>
  );

  // Menú del usuario
  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ padding: "0 20px", background: "#f0f2f5" }}>
      <Row justify="end" align="middle">
        <Col>
          <Space size="large">
            <Dropdown overlay={languageMenu} trigger={["click"]}>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <GlobalOutlined /> &nbsp;
                <span>{languageSelected}</span>
                <DownOutlined style={{ marginLeft: 8 }} />
              </div>
            </Dropdown>
            <Col>
              <Space
                direction="vertical"
                align="start"
                style={{ lineHeight: "normal" }}
              >
                <Text strong style={{ fontSize: "16px" }}>
                  {userInfo.usuario}
                </Text>
                <Text
                  type="secondary"
                  style={{ textTransform: "capitalize", fontSize: "14px" }}
                >
                  {userInfo.nombre_cliente}
                </Text>
              </Space>
            </Col>
            {/* Menú del usuario */}
            <Dropdown overlay={userMenu} trigger={["click"]}>
              <Avatar icon={<UserOutlined />} style={{ cursor: "pointer" }} />
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default Navbar;
