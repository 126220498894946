import { useEffect, useState } from "react";
import runReport from "../helpers/runReport";

const useRunReport = (reportName, filters) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    runReport(reportName, filters).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [reportName, filters]);

  return { data, isLoading };
};

export default useRunReport;
