import BACK_API from "../api/erpNextAPI";
import { message } from "antd";

const getPrintFormatPDF = async (
  doctypeName, // Nombre del DocType, por ejemplo: "Sales Invoice"
  docName, // Identificador del documento, por ejemplo: "DP224227"
  printFormat = "Standard", // Formato de impresión (opcional)
  lang = "en", // Idioma del documento (opcional)
  noLetterhead = 0, // Incluir o no el membrete: 0 (sí), 1 (no)
  letterhead = "Deliparva", // Membrete a usar (opcional, por defecto vacío)
  settings = {} // Configuraciones adicionales en formato JSON
) => {
  try {
    const url = `/proxy/api/method/frappe.utils.print_format.download_pdf?doctype=${encodeURIComponent(
      doctypeName
    )}&name=${encodeURIComponent(docName)}&format=${encodeURIComponent(
      printFormat
    )}&no_letterhead=${noLetterhead}&letterhead=${encodeURIComponent(
      letterhead
    )}&settings=${encodeURIComponent(JSON.stringify(settings))}&_lang=${lang}`;

    const response = await BACK_API().get(url, {
      // responseType: "blob", // Importante para manejar archivos binarios como PDFs
    });

    // Crear un enlace temporal para descargar el archivo
    const blob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${docName}.pdf`; // Nombre del archivo descargado
    link.click();

    message.success("PDF descargado exitosamente");
  } catch (error) {
    console.error(error);
    message.error("No fue posible descargar el PDF del documento");
  }
};
export default getPrintFormatPDF;
