import React, { useContext, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import useGetDocTypeList from "../hooks/useGetDocTypeList";
import { AppContext } from "../context/appContext";
import ViewerDoctype from "../components/viewerDoctype";
import getSalesInvoiceItem from "../helpers/getSalesInvoiceItem";
import ModalDetailDoc from "../components/modalDetailDoc";
import languageResources from "../enums/language";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const { userInfo, languageSelected, setOrderDetail } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [modalData] = useState();
  const [docType] = useState("SelfServiceOrder");
  const [fields] = useState([
    "name",
    "order_date",
    "customer",
    "customer_name",
    "status",
    "total_amount",
  ]);
  const [filter] = useState([
    ["customer", "=", userInfo.cliente_id],
    ["status", "!=", "cancelled"],
  ]);
  const [orderBy] = useState("name DESC");
  const { data, isLoading } = useGetDocTypeList(
    docType,
    fields,
    filter,
    orderBy
  );

  const navigate = useNavigate();

  const columns = [
    {
      title: "Date",
      dataIndex: "order_date",
      align: "center",
      key: "order_date",
    },
    {
      title: "Order",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      key: "status",
    },
    {
      title: "Total",
      dataIndex: "total_amount",
      align: "center",
      key: "total_amount",
      render: (_, { total_amount }) => {
        return `${formatToCurrency(total_amount)}`;
      },
    },
    {
      title: languageResources[languageSelected].page.salesOrders.actions,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (record) => {
        return (
          <>
            <Tooltip title="Show details">
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => gotoDetail(record)}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const gotoDetail = async (record) => {
    const resDetail = await getSalesInvoiceItem(
      record.name,
      "SelfServiceOrder"
    );
    setOrderDetail(resDetail);
    navigate(`/detailOrder/${record.name}`, {
      state: { orderDetail: resDetail },
    });
  };

  const onClose = () => {
    setShowModal(false);
  };

  const formatToCurrency = (number) => {
    if (isNaN(number)) {
      throw new Error("El valor proporcionado no es un número válido.");
    }

    return `$${parseFloat(number).toFixed(2)}`;
  };

  return (
    <>
      <ViewerDoctype
        columns={columns}
        data={data}
        isLoading={isLoading}
        title={languageResources[languageSelected].page.salesOrders.title}
      />
      <ModalDetailDoc visible={showModal} onClose={onClose} data={modalData} />
    </>
  );
};

export default Orders;
