// src/i18n.js

const languageEnum = {
  EN: {
    menu: {
      home: "Home",
      orders: "Orders",
      myOrders: "My Orders",
      newOrder: "New Order",
      invoices: "Invoices",
      products: "Products",
      reports: "Reports and Dashboard",
      support: "Support and Help",
      account: "My Account",
      logout: "Log Out",
    },

    page: {
      salesOrders: {
        title: 'Orders',
        actions: 'Actions'
      },
      salesInvoice: {
        title: 'Sales Invoices',
        actions: 'Actions'
      }
    }
  },
  ES: {
    menu: {
      home: "Inicio",
      orders: "Pedidos",
      myOrders: "Mis pedidos",
      newOrder: "Nuevo pedido",
      invoices: "Facturas",
      products: "Productos",
      reports: "Reportes y Dashboard",
      support: "Soporte y Ayuda",
      account: "Mi cuenta",
      logout: "Cerrar sesión",
    },

    page: {
      salesOrders: {
        title: 'Pedidos',
        actions: 'Acciones'
      },
      salesInvoice: {
        title: 'Facturas',
        actions: 'Acciones'
      }
    }
  },
};

export default languageEnum;
