import BACK_API from "../api/erpNextAPI"; // Asume que ya está configurado con axios o fetch
import { message } from "antd";

/**
 * Función para iniciar sesión en ERPNext.
 * @param {string} email - El correo electrónico del usuario.
 * @param {string} password - La contraseña del usuario.
 * @returns {boolean} - Devuelve true si el login fue exitoso, false en caso contrario.
 */
const loginToERPNext = async (email, password) => {
  try {
    const response = await BACK_API().post("/login", {
      usr: email,
      pwd: password,
    });

    if (response.status === 200) {
      message.success("Login success.");
      const {columns, result} = response.data.message
      return {columns, result, status: response.status}; // Login fue exitoso
    } else {
      message.error("Wrong password.");
      return {status: response.status};
    }
  } catch (error) {
    console.error("Login error:", error);
    return false;
  }
};

export default loginToERPNext;
